import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["BurgerDrawer"] */ "/vercel/path0/src/layout/Drawers/BurgerDrawer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/layout/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/layout/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMobile"] */ "/vercel/path0/src/layout/HeaderMobile/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/layout/Layout.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/HomePage/About/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/HomePage/Features/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/HomePage/Feedback/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/HomePage/Main/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/HomePage/Pricing/index.tsx");
