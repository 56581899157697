import cn from 'classnames';
import styles from './SkeletonStatic.module.scss';

type SkeletonStaticProps = {
  className?: string;
  variant?: 'short' | 'medium' | 'long' | 'full';
  height?: number;
};

export function SkeletonStatic({
  className,
  variant = 'full',
  height,
}: SkeletonStaticProps) {
  return (
    <div
      className={cn(styles.skeleton, styles[variant], className)}
      style={height ? { height: `${height}px` } : undefined}
    />
  );
}
