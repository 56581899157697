import cn from 'classnames';
import styles from './Analytics.module.scss';
import { useTranslations } from 'next-intl';
import { CardSubheading } from '../CardSubheading';
import { SkeletonStatic } from '../SkeletonStatic';

type AnalyticsProps = {
  className?: string;
};

export function Analytics({ className }: AnalyticsProps) {
  const wrapperClassName = cn(styles.main, className);
  const t = useTranslations('home.features.analytics');

  return (
    <div className={wrapperClassName}>
      <CardSubheading
        text={t.rich('title', {
          nbsp: () => <>&nbsp;</>,
        })}
      />
      <div className={styles.wrapper}>
        <div className={styles.charts}>
          <div className={styles.chartRow}>
            <div className={styles.pieChart}>
              <div className={styles.pieSegment1} />
              <div className={styles.pieSegment2} />
              <div className={styles.pieSegment3} />
            </div>
            <div className={styles.skeletons}>
              <SkeletonStatic />
              <SkeletonStatic />
              <SkeletonStatic />
            </div>
          </div>
          <div className={styles.chartRow}>
            <div className={cn(styles.skeletons, styles.bottom)}>
              <SkeletonStatic />
              <SkeletonStatic />
              <SkeletonStatic />
            </div>
            <div className={styles.lineChart}>
              <svg
                className={styles.graph}
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <path
                  className={styles.curve1}
                  d="M0,50 Q25,20 50,50 T100,50"
                />
                <path
                  className={styles.curve2}
                  d="M0,70 Q30,40 60,70 T100,70"
                />
                <path
                  className={styles.curve3}
                  d="M0,30 Q35,80 70,30 T100,30"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
