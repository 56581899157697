'use client';
import cn from 'classnames';
import styles from './TextArea.module.scss';
import { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type TextAreaProps = {
  label?: string;
  placeholder: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern: RegExp;
  defaultValue?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  description?: string;
};

export function TextArea({
  label,
  placeholder,
  required = false,
  maxLength = 1000,
  minLength = 0,
  pattern,
  defaultValue,
  name,
  className,
  disabled,
  readonly,
  description,
}: TextAreaProps) {
  const [focused, setFocused] = useState(false);
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: {
      required: { value: required, message: 'required' },
      maxLength: { value: maxLength, message: 'maxLength' },
      minLength: { value: minLength, message: 'minLength' },
      pattern: { value: pattern, message: 'pattern' },
    },
    defaultValue,
    control,
  });

  const error = fieldState.error ? fieldState.error.message : '';
  const wrapperClassName = cn(styles.wrapper, className);
  const inputClassName = cn(styles.container, {
    [styles.error]: error,
    [styles.disabled]: disabled,
    [styles.readonly]: readonly,
    [styles.focused]: focused,
  });

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      return;
    }

    field.onChange(e);
  };

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    field.onBlur();
  };

  return (
    <div className={wrapperClassName}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={inputClassName} onClick={handleContainerClick}>
        <textarea
          {...field}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          className={styles.input}
        />
      </div>
      <div className={styles.count}>
        {field.value ? field.value.length : 0} / {maxLength}
      </div>
      {error ? (
        <p className={styles.errorTag}>{handleError(error)}</p>
      ) : (
        <BottomLabel description={description} />
      )}
    </div>
  );
}

function BottomLabel({ description }: { description?: string }) {
  if (!description) return null;
  return <p className={styles.description}>{description}</p>;
}

function handleError(error: string) {
  switch (error) {
    case 'required':
      return 'This field is required';
    case 'pattern':
      return 'Invalid field format';
    case 'validate':
      return '';
    default:
      return 'Error in filling out the field';
  }
}
