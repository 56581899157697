'use client';
import cn from 'classnames';
import styles from './Pricing.module.scss';
import { Button } from '@/atoms/Button';
import { useTranslations } from 'next-intl';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRedirectToAccount } from '@/hooks/useRedirectToAccount';
import { useState } from 'react';
import { useAdaptiveLayout } from '@/hooks/useAdaptiveLayout';
import { BLOCK_IDS } from '@/constants';

type PricingProps = {
  className?: string;
};

type PlanType = {
  title: string;
  price: string;
  points: {
    text: string;
    isRed?: boolean;
  }[];
  button: {
    text: string;
    variant?: 'dark';
    onClick: () => void;
    disabled?: boolean;
  };
};

type PlanCardProps = {
  plan: PlanType;
};

const getPricingPlans = (
  t: (key: string) => string,
  redirect: () => void,
): PlanType[] => [
  {
    title: t('free.title'),
    price: t('free.price'),
    points: [
      { text: t('free.points.projects') },
      { text: t('free.points.email') },
      { text: t('free.points.pdf') },
      { text: t('free.points.commission'), isRed: true },
      { text: t('free.points.limit'), isRed: true },
    ],
    button: {
      text: t('free.button'),
      onClick: redirect,
    },
  },
  {
    title: t('pro.title'),
    price: t('pro.price'),
    points: [
      { text: t('pro.points.unlimited') },
      { text: t('pro.points.analytics') },
      { text: t('pro.points.notifications') },
      { text: t('pro.points.commission'), isRed: true },
    ],
    button: {
      text: t('pro.button'),
      variant: 'dark',
      onClick: redirect,
    },
  },
  {
    title: t('legend.title'),
    price: t('legend.price'),
    points: [
      { text: t('legend.points.pro') },
      { text: t('legend.points.tasks') },
      { text: t('legend.points.branding') },
      { text: t('legend.points.commission') },
      { text: t('legend.points.ai') },
    ],
    button: {
      text: t('legend.button'),
      onClick: () => {},
      disabled: true,
    },
  },
];

export function Pricing({ className }: PricingProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations('home.pricing.plans');
  const { redirect } = useRedirectToAccount();

  const PRICING_PLANS = getPricingPlans(t, redirect);

  return (
    <div className={wrapperClassName} id={BLOCK_IDS.PRICING}>
      <div className={styles.blocks}>
        {PRICING_PLANS.map((plan) => (
          <PlanCard key={plan.title} plan={plan} />
        ))}
      </div>
      <Swiper
        className={styles.slider}
        centeredSlides
        slidesPerView={1.2}
        spaceBetween={24}
        initialSlide={1}
      >
        {PRICING_PLANS.map((plan, index) => (
          <SwiperSlide key={`${plan.title}-${index}`}>
            <PlanCard key={plan.title} plan={plan} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

const PlanCard = ({ plan }: PlanCardProps) => {
  const t = useTranslations('home.pricing');
  const { button, points, price, title } = plan;
  const [isTouched, setIsTouched] = useState(false);
  const { isTablet } = useAdaptiveLayout();

  const handleTouch = () => {
    if (isTablet) {
      setIsTouched(!isTouched);
    }
  };

  return (
    <div
      className={cn(styles.block, {
        [styles.touched]: isTouched,
      })}
      onClick={handleTouch}
    >
      <div className={styles.content}>
        <div className={styles.priceContainer}>
          <div className={styles.priceWrapper}>
            <span className={styles.price}>{title}</span>
            <span className={styles.priceAmount}>{price}</span>
          </div>
          <div className={styles.hint}>{t('hint')}</div>
        </div>
        <div className={styles.points}>
          {points.map((point) => (
            <div key={point.text} className={styles.point}>
              <div
                className={cn(styles.dot, {
                  [styles.redDot]: point.isRed,
                })}
              />
              <span>{point.text}</span>
            </div>
          ))}
        </div>
      </div>
      <Button
        text={button.text}
        onClick={button.onClick}
        variant={button.variant}
        className={styles.button}
        disabled={button.disabled}
      />
    </div>
  );
};
