'use client';
import cn from 'classnames';
import styles from './Feedback.module.scss';
import { useTranslations } from 'next-intl';
import { Input } from '@/atoms/Input';
import { TextArea } from '@/atoms/TextArea';
import { Button } from '@/atoms/Button';
import { EMAIL_REGEX, MESSAGE_REGEX, NAME_REGEX } from '@/constants';
import { BLOCK_IDS } from '@/constants';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type FeedbackProps = {
  className?: string;
};

type FeedbackFormInputs = {
  name: string;
  email: string;
  message: string;
};

export function Feedback({ className }: FeedbackProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations('home.feedback');
  const methods = useForm<FeedbackFormInputs>();

  const onSubmit: SubmitHandler<FeedbackFormInputs> = async (data) => {
    return data;
  };

  return (
    <FormProvider {...methods}>
      <form
        className={wrapperClassName}
        id={BLOCK_IDS.FEEDBACK}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className={styles.box}>
          <div className={styles.container}>
            <h3 className={styles.title}>{t('title')}</h3>
            <p className={styles.description}>
              {t.rich('description', {
                nbsp: () => <>&nbsp;</>,
              })}
            </p>
            <Input placeholder={t('name')} name="name" pattern={NAME_REGEX} />
            <Input
              placeholder={t('email')}
              name="email"
              pattern={EMAIL_REGEX}
            />
            <TextArea
              placeholder={t('message')}
              name="message"
              pattern={MESSAGE_REGEX}
            />
            <Button
              text={t('send')}
              onClick={() => {}}
              type="submit"
              variant="dark"
              className={styles.button}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
