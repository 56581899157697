import cn from 'classnames';
import styles from './Gradient.module.scss';

type GradientProps = {
  className?: string;
};

export function Gradient({ className }: GradientProps) {
  const wrapperClassName = cn(styles.gradient, className);

  return <div className={wrapperClassName} />;
}
