import cn from 'classnames';
import styles from './Details.module.scss';
import { Switch } from '@/atoms/Switch';
import { useTranslations } from 'next-intl';
import { CardSubheading } from '../CardSubheading';
import { SkeletonStatic } from '../SkeletonStatic';

type DetailsProps = {
  className?: string;
};

export function Details({ className }: DetailsProps) {
  const wrapperClassName = cn(styles.main, className);
  const t = useTranslations('home.features.details');

  return (
    <div className={wrapperClassName}>
      <CardSubheading
        text={t.rich('title', {
          nbsp: () => <>&nbsp;</>,
        })}
      />
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.image}>
            <img
              src="/png/logo-big-gray.png"
              alt="logo"
              className={styles.logo}
            />
          </div>
          <div className={styles.textWrapper}>
            <SkeletonStatic />
            <SkeletonStatic />
            <SkeletonStatic />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.input}></div>
          <div className={styles.toggles}>
            <Switch onChange={() => {}} checked={true} onColor="#ff4d4d" />
            <Switch onChange={() => {}} checked={false} onColor="#ff4d4d" />
            <Switch onChange={() => {}} checked={true} onColor="#ff4d4d" />
          </div>
        </div>
      </div>
    </div>
  );
}
