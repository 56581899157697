'use client';
import cn from 'classnames';
import styles from './Features.module.scss';
import { Gradient } from '@/atoms/Gradient';
import { Table } from './components/Table';
import { Details } from './components/Details';
import { Invoice } from './components/Invoice';
import { Analytics } from './components/Analytics';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useState } from 'react';
import { BLOCK_IDS } from '@/constants';

type FeaturesProps = {
  className?: string;
};

type SliderArrowProps = {
  right?: boolean;
  onClick: () => void;
};

export function Features({ className }: FeaturesProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  return (
    <div className={wrapperClassName} id={BLOCK_IDS.FEATURES}>
      <Gradient className={styles.gradient} />
      <div className={styles.content}>
        <Swiper
          centeredSlides
          effect="slide"
          slidesPerView={1}
          spaceBetween={20}
          className={styles.slider}
          onSwiper={setSwiper}
          grabCursor
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          loop
        >
          <SliderArrow onClick={() => swiper?.slidePrev()} />
          <SwiperSlide className={styles.slide}>
            <Table />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <Details />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <Invoice />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <Analytics />
          </SwiperSlide>
          <SliderArrow right onClick={() => swiper?.slideNext()} />
        </Swiper>
      </div>
    </div>
  );
}

const SliderArrow = ({ right, onClick }: SliderArrowProps) => {
  return (
    <img
      src="/svg/chevron.svg"
      alt="arrow"
      className={cn(styles.arrow, {
        [styles.right]: right,
      })}
      onClick={onClick}
    />
  );
};
