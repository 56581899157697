import { ReactNode } from 'react';
import styles from './CardSubheading.module.scss';

type CardSubheadingProps = {
  text: ReactNode;
};

export function CardSubheading({ text }: CardSubheadingProps) {
  return <h2 className={styles.subheading}>{text}</h2>;
}
