'use client';
import cn from 'classnames';
import styles from './Invoice.module.scss';
import { useTranslations } from 'next-intl';
import { QRCodeSVG } from 'qrcode.react';
import { CardSubheading } from '../CardSubheading';
import { SkeletonStatic } from '../SkeletonStatic';

type InvoiceProps = {
  className?: string;
};

export function Invoice({ className }: InvoiceProps) {
  const wrapperClassName = cn(styles.main, className);
  const t = useTranslations('home.features.invoice');

  return (
    <div className={wrapperClassName}>
      <CardSubheading
        text={t.rich('title', {
          nbsp: () => <>&nbsp;</>,
        })}
      />
      <div className={styles.wrapper}>
        <div className={styles.qr}>
          <QRCodeSVG
            value="https://workice.ru"
            fgColor="#191919"
            width="100%"
            height="100%"
          />
        </div>
        <SkeletonStatic />
        <SkeletonStatic />
        <SkeletonStatic className={styles.footer} variant="medium" />
      </div>
    </div>
  );
}
