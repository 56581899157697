'use client';
import cn from 'classnames';
import styles from './Input.module.scss';
import { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { MESSAGE_REGEX } from '@/constants';
import { useTranslations } from 'next-intl';
import { TFunction } from '@/types';

type InputProps = {
  label?: string;
  placeholder: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
  type?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  description?: string;
};

export function Input({
  label,
  placeholder,
  required = false,
  maxLength = 100,
  minLength = 0,
  pattern = MESSAGE_REGEX,
  type = 'text',
  name,
  className,
  disabled,
  readonly,
  description,
}: InputProps) {
  const [focused, setFocused] = useState(false);
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: {
      required: { value: required, message: 'required' },
      maxLength: { value: maxLength, message: 'maxLength' },
      minLength: { value: minLength, message: 'minLength' },
      pattern: { value: pattern, message: 'pattern' },
    },
    control,
  });
  const t = useTranslations('common');

  const error = fieldState.error ? fieldState.error.message : '';
  const wrapperClassName = cn(styles.wrapper, className);
  const inputClassName = cn(styles.container, {
    [styles.error]: error,
    [styles.disabled]: disabled,
    [styles.readonly]: readonly,
    [styles.focused]: focused,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      return;
    }

    field.onChange(e);
  };

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    field.onBlur();
  };

  return (
    <div className={wrapperClassName}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={inputClassName} onClick={handleContainerClick}>
        <input
          {...field}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          type={type}
          className={styles.input}
        />
      </div>
      {error ? (
        <p className={styles.errorTag}>{handleError(type, error, t)}</p>
      ) : (
        <BottomLabel description={description} />
      )}
    </div>
  );
}

function BottomLabel({ description }: { description?: string }) {
  if (!description) return null;
  return <p className={styles.description}>{description}</p>;
}

function handleError(type: string, error: string, t: TFunction) {
  switch (error) {
    case 'required':
      return t('required');
    case 'pattern':
      if (type === 'password') return t('invalidPassword');
      if (type === 'e-mail') return t('invalidEmail');
      if (type === 'tel') return t('invalidPhone');
      if (type === 'datepicker') return t('invalidDate');
      return t('invalidField');
    case 'exists':
      return t('exists');
    case 'mismatch':
      return t('mismatch');
    default:
      return t('error');
  }
}
